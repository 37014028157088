var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapper',{staticClass:"settings-section rvs-weight-settings",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"settings-section__heading"},[_vm._v(" "+_vm._s(_vm.$t('settings.rvs-weight.title'))+" ")])]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"rvs-weight-settings__content settings-section__content"},[_c('div',{staticClass:"rvs-weight-settings__scroll-wrapper"},[_c('table',{staticClass:"table",attrs:{"width":"100%"}},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(width,key){return _c('th',{key:key,attrs:{"width":width,"align":key === 'lock' ? 'right' : 'left'},domProps:{"textContent":_vm._s(_vm.$t(key))}})}),0)]),(!_vm.isLoading)?_c('tbody',[_vm._l((_vm.tableData),function(_,key){return _c('tr',{key:key},[_c('td',{domProps:{"textContent":_vm._s(_vm.$tc(`rvs.rootCause.${key}_score`))}}),_c('td',[_c('div',{staticClass:"flex flex--gap"},[_c('input',{staticClass:"ci-input ci-input--small",attrs:{"type":"number"},domProps:{"value":String(_vm.tableData[key])},on:{"input":function($event){return _vm.recalculateWeights(key, $event.target?.value)}}}),_vm._v("% ")])]),_c('td',{attrs:{"valign":"middle","align":"right"}},[_c('CustomSwitch',{attrs:{"appearance":"next","value":_vm.lockedFields.includes(key)},on:{"input":function($event){return _vm.toggleLock(key)}}})],1)])}),_c('tr',{class:{
                            'rvs-weight-settings__table-footer': true,
                            'rvs-weight-settings__table-footer--danger': !_vm.isAbleToSave,
                        }},[_c('td',{domProps:{"textContent":_vm._s(_vm.$tc('titles.total'))}}),_c('td',{staticClass:"rvs-weight-settings__total-cell",domProps:{"textContent":_vm._s(Math.round(_vm.totalPercent) + '%')}}),_c('td',[(!_vm.isAbleToSave)?_c('p',{attrs:{"align":"left"},domProps:{"textContent":_vm._s(_vm.$tc('rvs.weightSettings.totalValidationMessage'))}}):_vm._e()])])],2):_c('tbody',_vm._l((_vm.tableData),function(_,key){return _c('tr',{key:key},[_c('td',[_c('span',{staticClass:"skeleton",domProps:{"textContent":_vm._s(_vm.$tc(`rvs.rootCause.${key}`))}})]),_c('td',[_c('div',{staticClass:"flex flex--gap"},[_c('input',{staticClass:"ci-input ci-input--small",attrs:{"type":"number","disabled":""}}),_vm._v("% ")])]),_c('td',{attrs:{"valign":"middle","align":"right"}},[_c('CustomSwitch',{attrs:{"appearance":"next","value":_vm.lockedFields.includes(key),"disabled":""},on:{"input":function($event){return _vm.toggleLock(key)}}})],1)])}),0)])])]),_c('div',{staticClass:"flex flex--acenter flex--gap"},[_c('button',{staticClass:"btn btn_primary",attrs:{"disabled":!_vm.isAbleToSave || _vm.isSaving},domProps:{"textContent":_vm._s(_vm.$t('save'))},on:{"click":_vm.save}}),_c('button',{staticClass:"btn btn_primary",attrs:{"disabled":!_vm.isAbleToSaveMultiple || _vm.isSaving},domProps:{"textContent":_vm._s(_vm.$t('rvs.saveForMultipleHotels'))},on:{"click":_vm.batchSave}}),_c('CustomMultiSelect',{attrs:{"items":_vm.hotels,"emitValuesOnly":""},model:{value:(_vm.hotelsToSave),callback:function ($$v) {_vm.hotelsToSave=$$v},expression:"hotelsToSave"}}),_c('p',{class:{
                    'settings-section__status': true,
                    'settings-section__status--error': _vm.isError,
                },attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$tc(_vm.statusMessage))}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }